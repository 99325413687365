// @import "~antd/dist/antd.compact.less";

// @import "~antd/dist/antd.less";

.logo {
  float: left;
  margin-right: 1rem;
}

.ant-menu-horizontal {
  border-bottom: none !important;
}

.ant-pagination-item-active a {
  color: #f4b886 !important;
}

.ant-pagination-item-active {
  border-color: #f4b886 !important;
}

.ant-pagination-item:focus-visible a,
.ant-pagination-item:hover a {
  color: #f4b886 !important;
}

@media only screen and (max-width: 576px) {
  .ant-pagination-options {
    display: block;
  }
}
.ant-select-selector:hover {
  border-color: #f4b886 !important;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #f4b886 !important;
}

.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-next:focus-visible .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #f4b886 !important;
}

.ant-switch-inner svg {
  vertical-align: text-top;
}

.anticon svg {
  vertical-align: baseline;
}

.ant-layout-header {
  padding: 0 2rem !important;
}

.ant-card-bordered {
  border-radius: 0.5rem !important;
}

.ant-carousel .slick-dots li button {
  background-color: #f4b886 !important;
}

@primary-color: #9351D6;@success-color: #2A9134;@link-color: #531C94;@warning-color: #faad14;@error-color: #C61F00;@white: #ffffff;@black: #1e1e1e;