@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
  position: fixed;
  max-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*Tailwind reset*/
svg {
  display: inline-block;
  vertical-align: middle;
}

img {
  display: unset !important;
}

.ant-avatar,
.ant-avatar-lg,
.ant-avatar-circle,
.ant-avatar-image {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

:root:root {
  --adm-color-primary: #9351d6;
}

.main-content {
  height: calc(100vh - 2rem);
  overflow: auto;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .main-content {
    height: calc(100vh - 10rem);
  }
}
/************/
